import React from 'react'
import {Button, Pagination} from 'antd'
import {TableFooterActions, TableFooterContainer, TableFooterInfo} from './styles'
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons'
import './style.css'

interface CustomTableFooterProps {
  pagination: {
    current: number
    pageSize: number
    total: number
  }
  handlePageChange: (page: number, pageSize: number) => void
  onCreate?: () => void
  onDelete?: () => void
  showCreate?: boolean
  showDelete?: boolean
  selectedRowKeys?: React.Key[]
  actionButtons?: React.ReactNode
}

const TableFooter: React.FC<CustomTableFooterProps> = ({
                                                         pagination,
                                                         handlePageChange,
                                                         onCreate,
                                                         onDelete,
                                                         showCreate = false,
                                                         showDelete = false,
                                                         selectedRowKeys = [],
                                                         actionButtons,
                                                       }) => {
  const { current, pageSize, total } = pagination

  const itemsDisplayed = Math.min(pageSize, total - (current - 1) * pageSize)

  return (
    <div style={{ width: '100%' }}>
      <TableFooterContainer>
        <TableFooterActions>
          {showCreate && (
            <Button onClick={onCreate} icon={<PlusOutlined/>}>
              Создать
            </Button>
          )}
          {showDelete && (
            <Button
              icon={<DeleteOutlined/>}
              onClick={onDelete}
              disabled={selectedRowKeys.length === 0}
            >
              Удалить
            </Button>
          )}
          {actionButtons}
        </TableFooterActions>
        {pagination && (
          <>
            <Pagination
              {...pagination}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={handlePageChange}
              size="small"
            />
            <TableFooterInfo>
              <div>Выделено <b>{selectedRowKeys.length}/{itemsDisplayed}</b></div>
              <div>Всего: <b>{total}</b></div>
            </TableFooterInfo>

          </>
        )}
      </TableFooterContainer>
    </div>
  )
}

export default TableFooter
