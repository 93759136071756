import styled from 'styled-components'

export const FilterWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding-bottom: 8px;
`

export const VehicleCard = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  width: 350px;
  position: relative;
  padding-right: 20px;

  &:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,0.18);
  }
`

export const VehicleCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 8px;
`

export const VehicleCardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: bold;
`

export const VehicleCardIcon = styled.div`
  font-size: 24px;
`

export const VehiclePyramidNumber = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VehicleCardInfoRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`

export const VehicleCardInfoCol = styled.div`
  width: 50%;
`

export const VehicleCardInfoItem = styled.div`
  font-size: 12px;
`

export const TableContainer = styled.div`
  .ant-table {
    font-size: 12px !important;
  }
`

export const VehicleModalContainer = styled.div`
  min-height: 200px;
  position: relative;
`

export const VehicleModalInfo = styled.div`
  margin-bottom: 20px;
`

export const VehicleModalInfoItem = styled.div`
  font-size: 16px;
`

export const TableScroller = styled.div<{ scrollWidth?: number }>`
  position: sticky;
  z-index: 1002;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  overflow: auto hidden;
  background: rgb(240, 240, 240);
  border-top: 1px solid rgb(221, 221, 221);
  margin-bottom: 10px;
  
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: ${props => props.scrollWidth + 'px' || '100%'};
  }
`



