import styled from 'styled-components'

export const TableContainer = styled.div`
  width: 100%;
  .sticky-footer .ant-table-body::-webkit-scrollbar {
    display: none;
  }

  .sticky-footer .ant-table-body::-webkit-scrollbar-track {
    display: none;
  }
`

export const TableScroller = styled.div<{ scrollWidth?: number }>`
  position: sticky;
  z-index: 1002;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  overflow: auto hidden;
  background: rgb(240, 240, 240);
  border-top: 1px solid rgb(221, 221, 221);
  margin-bottom: 10px;
  
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: ${props => props.scrollWidth + 'px' || '100%'};
  }
`