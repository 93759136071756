import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { createSession, getTaskCalendarCaseTypesData, getTaskCalendarHrTypesData } from '../selectors'

export const useFilterParams = () => {
  const { sessionData } = useSelector(createSession)
  const isGoLeader = sessionData.roles.includes(19)
  /*const isDealerLeader = sessionData.roles.includes(25)*/
  const enumsHr = useSelector(getTaskCalendarHrTypesData)
  const enumsCase = useSelector(getTaskCalendarCaseTypesData)
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))

  const stagesList = enumsHr.stages

  const taskTypesList = useMemo(() =>
    isGoLeader ? [
      {
        id: 0,
        value: 'Продажи',
        disabled: true,
      },
      ...enumsCase?.task_type,
      {
        id: 0,
        value: 'HR',
        disabled: true,
      },
      ...enumsHr?.task_type,
    ] : [
      ...enumsHr?.task_type,
      ...enumsCase?.task_type
    ], [enumsHr, enumsCase, isGoLeader])

  const responsibleList = (enumsHr?.responsible || [])
    .concat(enumsCase?.responsible || [])
    .reduce((acc, curr) => {
      if (!acc.find(i => i.id === curr.id)) {
        acc.push(curr)
      }
      return acc
    }, [])

  const enumsStagesEnum: EnumType[] = stagesList?.filter(v => v.id !== 'done')?.map(v => ({
    label: v.value,
    value: v.id,
  })) || []
  const tasksTypeEnum: EnumType[] = taskTypesList?.map(v => ({ label: v.value, value: String(v.id), disabled: v?.disabled })) || []
  const responsibleEnum: EnumType[] = responsibleList?.map(v => ({ label: v.value, value: String(v.id) })) || []
  const selectedTasks = urlParams?.task_type && urlParams.task_type.length ? urlParams?.task_type.split(',') : undefined

  const fields = useMemo<filtersType[]>(() => [
    {
      name: 'id',
      placeholder: 'ID',
      type: 'input-integer',
      value: urlParams?.id || undefined,
      options: {
        width: '170px',
        tooltip: 'ID',
      }
    },
    {
      name: 'phone',
      placeholder: 'Телефон',
      type: 'phone',
      value: urlParams?.phone || undefined,
      options: {
        width: '170px',
        tooltip: 'Телефон',
      }
    },
    {
      name: 'stage',
      placeholder: 'Стадия',
      type: 'select',
      value: urlParams?.stage || undefined,
      options: {
        width: '170px',
        enum: enumsStagesEnum,
        tooltip: 'Стадия',
      }
    },
    {
      name: 'task_type',
      placeholder: 'Тип задачи',
      type: 'select_multiple',
      value: selectedTasks,
      options: {
        width: '170px',
        enum: tasksTypeEnum,
        tooltip: 'Тип задачи',
      }
    },
    {
      name: 'responsible',
      placeholder: 'Ответственный',
      type: 'select',
      value: urlParams?.responsible || undefined,
      options: {
        width: '170px',
        enum: responsibleEnum,
        tooltip: 'Ответственный',
      },
    }
  ], [enumsCase, enumsHr, urlParams])

  return { fields, urlParams }
}
