import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Alert, Popconfirm, Table, TablePaginationConfig, Typography } from 'antd'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { calcTotalWidth, tableRowClick } from '../../../lib/utils/list'
import { TableWrapper } from './styles'
import dayjs from 'dayjs'
import { objectToUrl } from '../utils'
import FiltersList from '../../components/v2/filters'
import { ButtonActionWrapper } from '../../pages/list-users/styles'
import { DeleteOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { HStack } from '../../ui/Stack'
import { ModalCreateTaskByHrResponse } from '../../views/modal-create-task-searchByHrResponse'
import { Value } from '../elements'
import { QuickFilterButtons } from './ui/filter-buttons/filter-buttons'
import { EnumItem, Enums } from './interfaces'
import { PrintButton } from './ui/print-button/print-button'
import { getPrintPath } from '../../components/v2/export-excel-button'
import { getJsonSettingsByKey, TableSettingsButton, TransferItem } from '../../components/v2/table-settings-button'
import type { ColumnsType } from 'antd/es/table'
import { DataType } from '../orders/list/interface'
import { StageContainer } from '../orders/list/columns'

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const clearFlatObject = (obj: {[x: string]: any}) =>
  Object.entries(obj).reduce((acc, curr) => {
    const [key, val] = curr
    if (Boolean(val)) {
      acc[key] = val
    }
    return acc
  }, {})

export default function AdminObjectsListPage() {
  const history = useHistory()
  const { sessionData } = useSelector(createSession)
  const [enums, setEnums] = useState<Enums>({})
  const [data, setData] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: Number(urlParams?.page) || 1,
    pageSize: 10,
    total: 10,
    position: ['bottomCenter'],
    showSizeChanger: false,
  })
  const [options, setOptions] = useState<string[]>([])
  const [sorter, setSorter] = useState<any>({
    sort_field: urlParams?.field,
    sort_order: urlParams?.order,
  })
  const [filters, setFilters] = useState<any>({
    id: urlParams?.id,
    stage: urlParams?.stage,
    task_type: +urlParams?.task_type,
    phone: urlParams?.phone,
    responsible: +urlParams?.responsible,
    dates: urlParams?.dates,
  })
  const breadcrumbs = useMemo(() => (
    [{
      href: location.pathname,
      title: 'Задачи',
    }]
  ), [location.pathname])

  const isLeader = sessionData?.organization?.id === 1
  const isDealerLeader = sessionData.roles.includes(25)
  const taskType = sessionData?.organization?.type === 275 ? 'hr' : 'case'
  const { pathname } = useLocation()
  const jsonSettings = useSelector((state) => getJsonSettingsByKey(state, pathname))

  const convertEnumObject = (data: Record<string, any>): Record<string, EnumItem[]> => {
    const convertedData: Record<string, EnumItem[]> = {}
    Object.keys(data).forEach((key) => {
      const value = data[key]
      if (Array.isArray(value)) {
        convertedData[key] = value.map((item: { value: string, id: string, disabled?: boolean }) => ({
          label: item.value,
          value: item.id,
          disabled: item?.disabled || false,
        }));
      } else if (typeof value === 'object') {
        convertedData[key] = Object.keys(value).map(id => ({
          label: value[id],
          value: id,
        }))
      }
    })

    return convertedData
  }
  const [montageTypes, setMontageTypes] = useState<number[]>([])
  const [measurementTypes, setMeasurementTypes] = useState<number[]>([])
  const [deliveryTypes, setDeliveryTypes] = useState<number[]>([])
  const [saleTypes, setSaleTypes] = useState<number[]>([])

  useEffect(()=> {
    (async () => {
      setLoading(true)
      const [enums] = await Promise.all([
        fetchAPI(`/api/v2/task/${taskType}/enum`)
      ])
      if (taskType === 'case') {
        const buttonsGroup = {
          montage: enums?.task_type?.filter(item => item.value === 'Сделать монтаж').map(i => i.id),
          measurement: enums?.task_type?.filter(item => item.value === 'Сделать замер').map(i => i.id),
          delivery: enums?.task_type?.filter(item => item.value === 'Сделать доставку').map(i => i.id),
        }
        setMontageTypes(buttonsGroup.montage)
        setMeasurementTypes(buttonsGroup.measurement)
        setDeliveryTypes(buttonsGroup.delivery)
        setSaleTypes(
          enums?.task_type?.filter(item =>
            ![
              ...buttonsGroup.montage,
              ...buttonsGroup.measurement,
              ...buttonsGroup.delivery,
            ].includes(item.id)
          ).map(i => i.id)
        )
      }
      if (isLeader) {
        const [enumsHr] = await Promise.all([
          fetchAPI(`/api/v2/task/hr/enum`)
        ])
        if('task_type' in enums) {
          enums.task_type.unshift({
            id: 0,
            value: 'Продажи',
            disabled: true
          })
          enums.task_type.push({
            id: 0,
            value: 'HR',
            disabled: true
          })
        }
        enums.task_type = [...enums?.task_type, ...enumsHr?.task_type]
      }
      setEnums(convertEnumObject(enums))
      await getData()
    })()
      .finally(() => {
        setLoading(false)
      }
    )
  }, [])

  const formatPhone = (num: string) => {
    if (!num) return null
    if (num.length != 11) return num
    return num.replace(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5')
  }

  const addDataColumns: ColumnsType<DataType>[] = useMemo( () => {
    const beforeColumns: any[] = [
      {
        title: 'ID заказа/отклика',
        width: 80,
        dataIndex: 'card_id',
        key: 'card_id',
        sorter: true,
        sortOrder: sorter?.sort_field === 'id' ? sorter?.sort_order : null,
      },
      {
        title: 'Стадия заказа/отклика',
        width: 150,
        dataIndex: 'parent_stage_name',
        key: 'parent_stage_name',
        sorter: true,
        sortOrder: sorter?.sort_field === 'parent_stage' ? sorter?.sort_order : null,
        // render: (stage, row) => (<StageContainer stage={{ id: row.id, code: stage, name: stage, style: row.parent_stage_style }} />)
      },
      {
        title: 'Стадия',
        width: 130,
        dataIndex: 'stage',
        key: 'stage',
        sorter: true,
        sortOrder: sorter?.sort_field === 'stage' ? sorter?.sort_order : null,
        render: (stage: null|string) => {
          if (enums?.stages) {
            const current = enums.stages.filter(item => item?.value === stage)
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ padding: '2px 10px', borderRadius: 9 }}>
                  {current?.[0]?.['label'] || stage}
                </div>
              </div>
            )
          }
          return stage
        }
      }
    ]
    const mainColumns = [
      {
        title: 'Тип',
        width: 120,
        dataIndex: 'task_type',
        key: 'task_type',
        render: val => enums.task_type.filter(i => i.value === val)?.[0]?.label,
        sorter: true,
        sortOrder: sorter?.sort_field === 'task_type' ? sorter?.sort_order : null,
      },
      {
        title: 'Комментарий',
        width: 150,
        dataIndex: 'task',
        key: 'task',
      },
      {
        title: 'Телефон',
        width: 150,
        dataIndex: 'phone',
        key: 'phone',
        render: val => formatPhone(val),
      },
      {
        title: 'Контактное лицо',
        width: 150,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Дата',
        width: 250,
        dataIndex: 'deadline',
        key: 'deadline',
        render: (_, row) => (
          <dl className='attrs'>
            <dt>Дата создания:</dt>
            <dd>{row.created_at ? dayjs(row.created_at).format('DD.MM.YYYY HH:mm') : ''}</dd>
            <dt>Плановая дата:</dt>
            <dd>{row.deadline ? dayjs(row.deadline).format('DD.MM.YYYY HH:mm') : ''}</dd>
            <dt>Дата завершения:</dt>
            <dd>{row.completed_at ? dayjs(row.completed_at).format('DD.MM.YYYY HH:mm') : '- не завершено -'}</dd>
          </dl>
        ),
        sorter: true,
        sortOrder: sorter?.sort_field === 'deadline' ? sorter?.sort_order : null,
      },
      {
        title: 'Причина завершения',
        width: 150,
        dataIndex: 'complete_reason',
        key: 'complete_reason',
        sorter: true,
        sortOrder: sorter?.sort_field === 'complete_reason' ? sorter?.sort_order : null,
      },
      {
        title: 'Автор',
        width: 200,
        dataIndex: 'author',
        key: 'author',
        sortOrder: sorter?.sort_field === 'author' ? sorter?.sort_order : null,
      },
      {
        title: 'Ответственные',
        width: 150,
        dataIndex: 'responsible',
        render: (val: null|string[]) => {
          if (!val) return val
          return val.map((item, index) => index === 0 ? (<>{item}</>) : (<>,<br/>{item}</>))
        },
        key: 'responsible',
      },
    ]
    const actionColumn = [
      {
        title: 'Действия',
        dataIndex: 'operation',
        width: 80,
        align: 'center',
        style: { backgroundColor: '#FFF' },
        fixed: 'right',
        render: (_: any, record) => {
          return (
            <span
              style={{ display: 'flex', justifyContent: 'center' }}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <Typography.Link>
                <ButtonActionWrapper title="Удалить">
                  <Popconfirm
                    title="Удалить поле?"
                    onConfirm={() => remove(record.id)}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </ButtonActionWrapper>
              </Typography.Link>
            </span>
          )
        },
      }
    ]
    const beforeLeaderColumns = [
      {
        title: 'Бренд',
        width: 150,
        dataIndex: 'brand',
        key: 'brand',
        sorter: true,
        render: val => <Value data={val}/>
      },
      {
        title: 'Офис',
        width: 200,
        dataIndex: 'office',
        key: 'office',
        sorter: true,
        render: val => <Value data={val}/>
      }
    ]
    return isLeader ? [...beforeColumns, ...beforeLeaderColumns, ...mainColumns, ...actionColumn] : [...beforeColumns, ...mainColumns]
  }, [enums, sorter])

  /**
   * Удаляет запись
   * @param id
   */
  const remove = id => {
    fetchAPI(`/api/v2/task/${id}`, { method: 'DELETE' }).then(() => {
      getData()
    }).catch(e => {
      console.error({e})
    })
  }

  const totalWidth: any = useMemo(() => calcTotalWidth(addDataColumns), [ addDataColumns])
  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(addDataColumns).filter(item => item.title), [addDataColumns])

  const cbTableRowClick = useCallback((item: any) => {
    const type = item?.card_type === 'case' ? 'order' : item?.card_type
    const cardId = item.card_id
    return history.push(`/${type}/${cardId}`)
  }, [history])

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      setData([])
      const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
      /*
      if (!isDealerLeader && sessionData?.user?.id) {
        currentUrlParams['responsible'] = sessionData.user.id
      }
      */
      fetchAPI(`/api/v2/tasks?${objectToUrl(currentUrlParams)}`)
        .then(
          ({ data, pagination, sorter, options } ) => {
            updatePagination(pagination)
            setSorter(sorter)
            setData(data)
            setLoading(false)
            setOptions(options)
          },
        )
        .catch(e => {
          setPageErrors([e])
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [])

  const rowClassName = useCallback(record => {
    return `table-row-${record.stage}`
  }, [])

  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(
    newState => {
      setPagination({
        ...pagination,
        ...newState,
      })
    },
    [pagination, setPagination],
  )

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handlerTableChange = useCallback( async (pagination, _filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
    }
    if (current?.page === 1) {
      delete current.page
    }

    if (current?.sort_order === undefined || current?.sort_order === null) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    await getData()
  }, [] )

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const handlerFilterChange = useCallback(filter => {
    setFilters(filter)
    history.push({ search: new URLSearchParams(filter).toString() })
    getData()
  }, [setFilters, getData])

  const handlerQuickFilters = useCallback(taskTypes => {
    setFilters(prev => {
      const newState = clearFlatObject({ ...prev, task_type: taskTypes })
      history.push({ search: new URLSearchParams(newState).toString() })
      return newState
    })
    getData()
  }, [setFilters])

  const excelPath = useMemo(() => {
    return getPrintPath('/api/v2/tasks', new URLSearchParams(location.search))
  }, [location.search])

  const viewColumns = useMemo(() => {
    if (jsonSettings && jsonSettings?.length > 0) {
      let result: any[] = []
      for (let i = 0; i < jsonSettings.length; i++) {
        // @ts-ignore
        let item = finalColumns.find(column => jsonSettings[i] === column.dataIndex)
        if (item) {
          result.push(item)
        }
      }
      return result
    }
    return finalColumns
  }, [finalColumns, jsonSettings])

  const settingsColumns = useMemo(() => finalColumns.map(column => ({
    key: column?.['dataIndex'], title: column?.['title'],
  })), [finalColumns]) as TransferItem[]

  const buttons = useMemo(() => [
    {
      name: 'Продажи',
      isActive: false,
      types: saleTypes,
    },
    {
      name: 'Замеры',
      isActive: false,
      types: measurementTypes,
    },
    {
      name: 'Доставка',
      isActive: false,
      disabled: true,
      types: deliveryTypes,
    },
    {
      name: 'Монтажи',
      isActive: false,
      types: montageTypes,
    },
  ], [saleTypes, measurementTypes, deliveryTypes, montageTypes])

  const filterFields: filtersType[] = useMemo(() => [
    {
      name: 'id',
      placeholder: 'ID',
      type: 'input-integer',
      value: filters?.id || undefined,
      options: {
        width: '170px',
        tooltip: 'ID',
      }
    },
    {
      name: 'phone',
      placeholder: 'Телефон',
      type: 'phone',
      value: filters?.phone || undefined,
      options: {
        width: '170px',
        tooltip: 'Телефон',
      }
    },
    {
      name: 'responsible',
      placeholder: 'Ответственный',
      type: 'select',
      value: filters?.responsible || undefined,
      // disabled: !isDealerLeader && sessionData?.user?.id,
      options: {
        width: '170px',
        enum: enums?.responsible || [],
        tooltip: 'Ответственный',
      }
    },
    {
      name: 'stage',
      placeholder: 'Стадия',
      type: 'select',
      value: filters?.stage || undefined,
      options: {
        width: '170px',
        enum: enums?.stages || [],
        tooltip: 'Стадия',
      }
    },
    {
      name: 'task_type',
      placeholder: 'Тип задачи',
      type: 'select_multiple',
      value: filters?.task_type || undefined,
      options: {
        width: '170px',
        enum: enums?.task_type?.map(item => ({
          ...item,
          value: String(item.value),
        })) || [],
        tooltip: 'Тип задачи',
      }
    },
    {
      name: 'dates',
      placeholder: ['Начальная', 'Конечная'],
      type: 'dates',
      value: filters?.dates || undefined,
      options: {
        width: '220px',
        tooltip: 'Плановая дата',
      }
    },
  ], [enums, filters, isDealerLeader, sessionData])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <HStack align='end' justify='end' style={{ marginBottom: '20px' }}>
        <Suspense fallback=''>
          {options?.length > 0 && options.includes('show_quick_filters_in_task_list_and_calendar') && (
            <QuickFilterButtons
              buttonsList={buttons}
              onChange={handlerQuickFilters}
              disabled={loading}
            />
          )}
          <ModalCreateTaskByHrResponse
            entityType={taskType}
            titleInputSearch={taskType === 'hr' ? 'ID отклика' : 'Номер заказа'}
          />
        </Suspense>
      </HStack>
      <div>
        <FiltersList
          isChangeByButtons={true}
          filters={filterFields}
          onChange={handlerFilterChange}
        />
      </div>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message='При выполнении операции возникла ошибка:'
            showIcon
            type='error'
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}

      <HStack gap={'24'} align={'start'} justify={'end'} style={{marginBottom: '20px'}}>
        <PrintButton
          path={excelPath}
          columns={viewColumns}
          stages={enums?.stages}
        />
        <TableSettingsButton items={settingsColumns}/>
      </HStack>
      <TableWrapper>
        <Table
          rowKey='id'
          columns={viewColumns}
          rowClassName={rowClassName}
          size='small'
          dataSource={data}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={pagination}
          loading={loading}
          onHeaderRow={() => ({ style: { fontSize: 13 } })}
          onChange={handlerTableChange}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
    </B2BLayout>
  )
}
