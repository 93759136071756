import React, {memo, useCallback} from "react";
import fetchAPI from "../../../../../lib/utils/fetch-api";
import {ExportExcelButton, format2Print} from "src/react-app/components/v2/export-excel-button";

export interface DeliveriesPrintButtonProps {
    path: string,
    columns: any[],
    stages: any[],
}

const excludeColumns = ['stage', 'approval_mark', 'payment_verified', 'order_status_in_wd', 'operation'];
export const DeliveriesPrintButton: React.FC<DeliveriesPrintButtonProps> = memo((props) => {
    const {path, columns, stages} = props;

    const localColumns = columns.filter(column => !excludeColumns.includes(column.key));

    const handlePrintClick = useCallback(async () => {
        try {
            const url = new URL(path, window.location.origin)
            url.searchParams.set('excel', 'y')

            const response = await fetchAPI(url.toString());
            const data = response.data;
            if (!data) {
                return
            }


            const viewColumnNames = localColumns.map((column) => column.dataIndex).filter(key => !excludeColumns.includes(key));
            const count = data.length;

            console.log('viewColumnNames', viewColumnNames)

            for (let i = 0; i < count; i++) {
                let keys = Object.keys(data[i])
                keys.forEach((key) => {
                    if (!viewColumnNames.includes(key)) {
                        return;
                    }
                    if (key === 'stage') {
                        data[i][key] = stages.find((stage) => stage.code === data[i][key])?.name || data[i][key];
                        return;
                    }
                    if (typeof data[i][key] === 'string' && data[i][key].includes('.') && !isNaN(data[i][key])) {
                        data[i][key] = data[i][key].replace('.', ',');
                    }

                    data[i][key] = format2Print(key, data[i][key]);
                })
            }

            console.log('data', data)

            return data
        } catch (e) {
            console.log('handlePrintClick', e)
        }
    }, [columns, localColumns, stages, path])


    return (
        <ExportExcelButton
            fileName={'Доставки'}
            path={path}
            columns={localColumns}
            onPrintClick={handlePrintClick}
        />
    )
})