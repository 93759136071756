import React, {useEffect, useState} from 'react'
import {Button, message, Modal, Space, Upload} from 'antd'
import {PlusOutlined, UploadOutlined} from '@ant-design/icons'
import {UploadProps} from 'antd/es/upload'

interface ActionButtonsProps {
  onUnloadFromWD: () => void
  uploadProps: UploadProps
  onSendToSchedex: () => void
  onUnloadFromSchedex: () => void
  isSendToSchedexEnabled: boolean
  isUnloadFromSchedexEnabled: boolean
  onSetPlanned: () => void
  isSetPlannedEnabled: boolean
  onSetVehicle: () => void
  isSetVehicleEnabled: boolean
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
                                                       onUnloadFromWD,
                                                       uploadProps,
                                                       onSendToSchedex,
                                                       onUnloadFromSchedex,
                                                       onSetPlanned,
                                                       onSetVehicle,
                                                       isSendToSchedexEnabled,
                                                       isUnloadFromSchedexEnabled,
                                                       isSetPlannedEnabled,
                                                       isSetVehicleEnabled,
                                                     }) => {
  const [isLockedSchedex, setIsLockedSchedex] = useState(false)
  const [schedexLockedTimer, setSchedexLockedTimer] = useState<number | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    const checkLockStatus = async () => {
      try {
        const response = await fetch('/api/schedex/lock/status')
        const data = await response.json()

        if (data.isLocked) {
          setIsLockedSchedex(true)
          setSchedexLockedTimer(data.timeout)
        }
      } catch (error) {
        console.error('Ошибка проверки блокировки:', error)
        message.error('Ошибка проверки блокировки')
      }
    }

    checkLockStatus()

    //const eventSource = new EventSource('http://localhost:7070/api/schedex/events/lock') // Тестирование на локалке
    const eventSource = new EventSource('/api/schedex/events/lock')

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)
      setIsLockedSchedex(data.isLocked)

      if (data.isLocked && data.timeout) {
        setSchedexLockedTimer(data.timeout)
      } else {
        setSchedexLockedTimer(null)
      }
    }

    eventSource.onerror = () => {
      console.error('Ошибка подключения к SSE')
      eventSource.close()
    }

    return () => {
      eventSource.close()
    }
  }, [])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>

    if (isLockedSchedex && schedexLockedTimer && schedexLockedTimer > 0) {
      interval = setInterval(() => {
        setSchedexLockedTimer((prev) => {
          if (prev && prev > 0) {
            return prev - 1
          } else {
            clearInterval(interval)
            setIsLockedSchedex(false)
            setIsModalVisible(false)
            return 0
          }
        })
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [isLockedSchedex, schedexLockedTimer])

  const handleSendToSchedexClick = () => {
    if (isLockedSchedex && schedexLockedTimer && schedexLockedTimer > 0) {
      setIsModalVisible(true)
    } else {
      onSendToSchedex()
    }
  }

  const handleSetVehicleClick = () => {
    if (isLockedSchedex && schedexLockedTimer && schedexLockedTimer > 0) {
      setIsModalVisible(true)
    } else {
      onSetVehicle()
    }
  }

  return (
    <Space style={{width: '100%'}} wrap>
      <Button
        onClick={onUnloadFromWD}
      >
        Выгрузить заказы из WD
      </Button>
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined/>}>Загрузить CSV</Button>
      </Upload>
      <Button
        type="primary"
        disabled={!isSendToSchedexEnabled}
        onClick={handleSendToSchedexClick}
      >
        Отправить в Шедекс
      </Button>
      <Button
        type="primary"
        disabled={!isUnloadFromSchedexEnabled}
        onClick={onUnloadFromSchedex}
      >
        Выгрузить из Шедекс
      </Button>
      <Button
        type="primary"
        disabled={!isSetPlannedEnabled}
        onClick={onSetPlanned}
      >
        Запланировать
      </Button>
      <Button
        type="primary"
        disabled={!isSetVehicleEnabled}
        onClick={handleSetVehicleClick}
      >
        Назначить экипаж
      </Button>
      <Modal
        title="Выгрузка в Шедекс недоступна"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsModalVisible(false)}>
            Закрыть
          </Button>,
        ]}
      >
        <p>Превышен лимит вызовов: лимит для загрузки заказов составляет 1 раз в 60 секунд.</p>
        {schedexLockedTimer !== null && (
          <p>Попробуйте снова через <b>{schedexLockedTimer}</b> секунд.</p>
        )}
      </Modal>
    </Space>
  )
}

export default React.memo(ActionButtons)
