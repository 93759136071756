import React, {useRef, useEffect, useState} from 'react'
import {Table} from 'antd'
import {TableContainer, TableScroller} from './styles'
import TableFooter from '../../layouts/b2b/table-footer'
import {DataTableProps} from "./interface";

function DataTable<T extends { id: React.Key; type?: string | null } & Record<string, any>>({
                                                                                              columns,
                                                                                              data,
                                                                                              loading = false,
                                                                                              rowKey,
                                                                                              pagination,
                                                                                              selectedRowKeys,
                                                                                              onSelectChange,
                                                                                              onRowClick,
                                                                                              onCreate,
                                                                                              onDelete,
                                                                                              onPageChange,
                                                                                              showDelete = false,
                                                                                              size = 'small',
                                                                                              className,
                                                                                              rowClassName,
                                                                                              bordered = false,
                                                                                              onTableChange,
                                                                                              highlightLastRow = false,
                                                                                              headerRef
                                                                                            }: DataTableProps<T>) {
  const internalHeaderRef = useRef<HTMLDivElement | null>(null)
  const effectiveHeaderRef = headerRef || internalHeaderRef
  const [offsetHeader, setOffsetHeader] = useState(0)
  const [lastSelectedOrder, setLastSelectedOrder] = useState<number | null>(null)

  // Смещать шапку таблицы если фиксированная шапка с фильтрами становится больше по высоте
  useEffect(() => {
    const updateOffset = () => {
      requestAnimationFrame(() => {
        if (effectiveHeaderRef.current) {
          setOffsetHeader(effectiveHeaderRef.current.clientHeight + 28)
        }
      })
    }

    const resizeObserver = new ResizeObserver(updateOffset)
    const mutationObserver = new MutationObserver(updateOffset)

    if (effectiveHeaderRef.current) {
      resizeObserver.observe(effectiveHeaderRef.current)
      mutationObserver.observe(effectiveHeaderRef.current, { childList: true, subtree: true })
    }

    updateOffset()

    window.addEventListener('resize', updateOffset)

    return () => {
      resizeObserver.disconnect()
      mutationObserver.disconnect()
      window.removeEventListener('resize', updateOffset)
    }
  }, [effectiveHeaderRef])

  // Запоминать в какой заказ последний раз переходили и подсвечивать
  useEffect(() => {
    const storedOrderId = sessionStorage.getItem('lastSelectedOrder')
    if (storedOrderId) {
      setLastSelectedOrder(Number(storedOrderId))
      sessionStorage.setItem('lastSelectedOrder', '')
    }
  }, [])

  useEffect(() => {
    if (lastSelectedOrder) {
      setTimeout(() => {
        const highlightedRow = document.querySelector('.highlighted-row')
        if (highlightedRow) {
          highlightedRow.classList.add('stop-animation')
        }
      }, 2000)
    }
  }, [lastSelectedOrder])

  // Кастомный горизонтальный скролл, который всегда виден
  const tableBodyRef = useRef<HTMLDivElement | null>(null)
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const tableBody = document.querySelector('.ant-table-body') as HTMLDivElement | null
    if (tableBody) {
      tableBodyRef.current = tableBody
    }
  }, [])

  useEffect(() => {
    const syncScroll = () => {
      if (tableBodyRef.current && scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft = tableBodyRef.current.scrollLeft
      }
    }

    const syncTableScroll = () => {
      if (tableBodyRef.current && scrollContainerRef.current) {
        tableBodyRef.current.scrollLeft = scrollContainerRef.current.scrollLeft
      }
    }

    if (tableBodyRef.current) {
      tableBodyRef.current.addEventListener('scroll', syncScroll)
    }

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', syncTableScroll)
    }

    return () => {
      if (tableBodyRef.current) {
        tableBodyRef.current.removeEventListener('scroll', syncScroll)
      }
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', syncTableScroll)
      }
    }
  }, [])

  return (
    <TableContainer className={className}>
      <Table<T>
        sticky={{
          offsetHeader,
        }}
        showHeader={data.length > 0}
        showSorterTooltip={{ overlay: 'Нажмите для сортировки', placement: 'bottom' }}
        columns={columns}
        rowKey={rowKey}
        dataSource={data}
        pagination={false}
        loading={loading}
        onChange={onTableChange}
        onRow={onRowClick ? (record) => ({ onClick: () => {
            if (highlightLastRow) {
              sessionStorage.setItem('lastSelectedOrder', String(record?.id))
            }
            onRowClick(record)
          } }) : undefined}
        scroll={{ x: 'max-content' }}
        size={size}
        rowSelection={
          selectedRowKeys && onSelectChange
            ? {
              selectedRowKeys,
              onChange: onSelectChange,
            }
            : undefined
        }
        rowClassName={(record) => {
          const highlightClass =
            highlightLastRow && lastSelectedOrder !== null && record.id === lastSelectedOrder
              ? 'highlighted-row'
              : ''

          const customClass = rowClassName ? rowClassName(record) : ''

          return [highlightClass, customClass].filter(Boolean).join(' ')
        }}
        bordered={bordered}
        className='sticky-footer'
        footer={() => (
          <>
            <TableScroller ref={scrollContainerRef} scrollWidth={tableBodyRef.current?.scrollWidth}/>
            <TableFooter
              pagination={pagination}
              handlePageChange={onPageChange}
              showCreate
              showDelete={showDelete}
              onCreate={onCreate}
              onDelete={onDelete}
              selectedRowKeys={selectedRowKeys}
            />
          </>
        )}
      />
    </TableContainer>
  )
}

export default DataTable
