import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import {Popconfirm, Tag} from 'antd'
import { DataType } from './interface'
import { ButtonActionWrapper } from '../../../components/list-operations/styles'
import { DeleteTwoTone } from '@ant-design/icons'
import { CountBallInline, Stage } from '../../../components/activity-timeline/styles'
import { formatPrice } from '../../utils'
import { Value } from '../../elements'

const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
  }
  return phoneNumber
}

const renderYesNoTag = (val: boolean) => (
  <Tag
    color={val ? '#87d068' : '#f50'}
    style={{
      border: '2px solid #fff',
      fontWeight: 'bold',
      padding: '0 8px',
    }}
  >
    {val ? 'Да' : 'Нет'}
  </Tag>
)

const ColumnId: ColumnsType<DataType> = [
  /*{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left'
  },*/
  {
    title: 'Стадия',
    dataIndex: 'stage',
    key: 'stage',
    width: 120,
    render: (_, { stage }) => <StageContainer stage={stage} />
  },
]

const ColumnsGeneral: ColumnsType<DataType> = [
  {
    title: '№ ТЗ',
    dataIndex: 'order_reference',
    key: 'order_reference',
    width: 100,
    sorter: true,
  },
  {
    title: 'Плановая дата доставки',
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    width: 110,
    sorter: true,
    render: val => val ? dayjs(val).format('DD.MM.YYYY') : <Value data={val}/>
  },
  {
    title: 'Склад',
    dataIndex: 'distribution_centre_name',
    key: 'distribution_centre_name',
    width: 70,
  },
  {
    title: 'Дилер',
    dataIndex: 'dealer',
    key: 'dealer',
    width: 160,
  },
  {
    title: 'Имя клиента',
    dataIndex: 'client_name',
    key: 'client_name',
    width: 160,
    sorter: true,
  },
  {
    title: 'Телефон клиента',
    dataIndex: 'client_phone',
    key: 'client_phone',
    width: 140,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'Адрес доставки',
    dataIndex: 'customer_location_address',
    key: 'customer_location_address',
    width: 180,
  },
  {
    title: 'Интервал доставки',
    dataIndex: 'drop_duration',
    key: 'drop_duration',
    width: 80,
  },
  {
    title: 'Вес',
    dataIndex: 'capacity',
    key: 'capacity',
    width: 60,
  },
  {
    title: 'Объем',
    dataIndex: 'volume',
    key: 'volume',
    width: 60,
  },
  {
    title: 'Кол-во изделий',
    dataIndex: 'cost',
    key: 'cost',
    width: 70,
  },
  {
    title: 'Дополнительные инструкции к заказу',
    dataIndex: 'additional_instructions',
    key: 'additional_instructions',
    width: 160,
    render: (text: string, record: DataType) => {
      const style: React.CSSProperties = record.highlight_comment
        ? { backgroundColor: '#fff', color: '#f00', fontWeight: 700, padding: '4px 8px' }
        : {}
      return <span style={style}>{text || ''}</span>
    }
  },
  {
    title: 'Время С',
    dataIndex: 'drop_window_start',
    key: 'drop_window_start',
    width: 80,
    render: val => val ? dayjs(val).format('HH:mm') : <Value data={val}/>
  },
  {
    title: 'Время До',
    dataIndex: 'drop_window_end',
    key: 'drop_window_end',
    width: 80,
    render: val => val ? dayjs(val).format('HH:mm') : <Value data={val}/>
  },
  {
    title: 'Приоритет',
    dataIndex: 'priority',
    key: 'priority',
    width: 85,
  },
  {
    title: 'Очередность заказа в маршруте',
    dataIndex: 'sequence_in_run',
    key: 'sequence_in_run',
    width: 90,
    sorter: true,
  },
  {
    title: 'Пирамида',
    dataIndex: 'pyramid_number',
    key: 'pyramid_number',
    width: 120,
    sorter: true,
    render: val => val ? `Пирамида №${val}` : ''
  },
  {
    title: 'Машина',
    dataIndex: 'vehicle',
    key: 'vehicle',
    width: 130,
    sorter: true,
  },
  {
    title: 'Первичная дата доставки',
    dataIndex: 'old_delivery_date',
    key: 'old_delivery_date',
    width: 150,
    sorter: true,
    render: val => val ? dayjs(val).format('DD.MM.YYYY') : <Value data={val}/>
  },
  {
    title: 'Сумма доставки',
    dataIndex: 'delivery_amount',
    key: 'delivery_amount',
    width: 100,
  },
  {
    title: 'Оплата проверена',
    dataIndex: 'payment_verified',
    key: 'payment_verified',
    width: 90,
    render: val => renderYesNoTag(val),
  },
  {
    title: 'Диспетчер',
    dataIndex: 'responsible_manager',
    key: 'responsible_manager',
    width: 130,
  },
  {
    title: 'Сложность доставки',
    dataIndex: 'delivery_complexity',
    key: 'delivery_complexity',
    width: 90,
  },
  {
    title: 'Проезд',
    dataIndex: 'passage',
    key: 'passage',
    width: 80,
  },
  {
    title: 'Телефон дилера',
    dataIndex: 'dealer_phone',
    key: 'dealer_phone',
    width: 110,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'Отметка о согласовании',
    dataIndex: 'approval_mark',
    key: 'approval_mark',
    width: 80,
    render: val => renderYesNoTag(val),
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    width: 100,
  },
  {
    title: 'Телефон менеджера',
    dataIndex: 'manager_phone',
    key: 'manager_phone',
    width: 110,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'Возможность проезда',
    dataIndex: 'passage_availability',
    key: 'passage_availability',
    width: 90,
  },
  {
    title: 'Комментарий',
    dataIndex: 'floor_elevator',
    key: 'floor_elevator',
    width: 100,
    render: (val: string) => {
      const style: React.CSSProperties = val
        ? {
          color: '#ff0000',
          fontWeight: 700,
          display: 'block',
          wordWrap: 'normal',
          wordBreak: 'normal'
        }
        : {}
      return <span style={style}>{val || ''}</span>
    }
  },
  {
    title: 'Статус заказа в WD',
    dataIndex: 'order_status_in_wd',
    key: 'order_status_in_wd',
    width: 130,
  },
  {
    title: 'Что не готово? Когда?',
    dataIndex: 'what_is_not_ready_and_when',
    key: 'what_is_not_ready_and_when',
    width: 130,
  },
  {
    title: 'Сложность',
    dataIndex: 'delivery_complexity',
    key: 'delivery_complexity',
    width: 85,
  },
  {
    title: 'Подъем',
    dataIndex: 'lifting',
    key: 'lifting',
    width: 80,
    render: val => renderYesNoTag(val),
  },
  {
    title: 'Превышение по допам',
    dataIndex: 'excess_for_additionals',
    key: 'excess_for_additionals',
    width: 95,
    render: val => renderYesNoTag(val),
  },
  {
    title: 'Дата создания',
    width: 140,
    sorter: true,
    dataIndex: 'created_at',
    key: 'created_at',
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  },
  {
    title: 'Дата обновления',
    width: 140,
    sorter: true,
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  }
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    dataIndex: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены, что хотите удалить эту запись?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export const getColumns = (leader, sorter) => {
  const columns = leader ? ColumnId.concat(ColumnsGeneral/*, ColumnAction*/) : ColumnId.concat(ColumnsGeneral)
  return columns.map(item => {

    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}


export function StageContainer({ stage }) {
  if (stage) {
    return <Stage style={stage?.style} wrap>{stage.name}</Stage>
  }
  return <>...</>
}
