import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import { Alert, Modal, Spin } from 'antd'
import { DefaultJsonForm } from '../../../../../components/v2/default-json-form'
import schema from './schema.json'
import { convertData2JsonForm } from '../../../../../shared/lib/convertData2JsonForm'
import { isFormDataRequired } from '../../../../../../lib/utils/collections'

interface IFormData {
  vehicle_id?: number
  pyramid_number?: number
}

interface MeasurementModalProps {
  isModalOpen: boolean
  onOk?: ((formData: IFormData) => void) | undefined
  onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
  enums?: any
  selectedPyramid?: number
}

export const VehicleModal: React.FC<MeasurementModalProps> = memo((props: MeasurementModalProps) => {
  const { isModalOpen, onOk, onCancel, enums, selectedPyramid } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [formData, setFormData] = useState<IFormData>({
    vehicle_id: undefined,
    pyramid_number: selectedPyramid || undefined,
  })

  useEffect(() => {
    if (selectedPyramid !== formData.pyramid_number) {
      setFormData((prevData) => ({
        ...prevData,
        pyramid_number: selectedPyramid || undefined,
      }))
    }
  }, [selectedPyramid])

  const handleOk = useCallback(() => {
    const isRequiredFields = isFormDataRequired(formData, actualSchema)
    if (!isRequiredFields) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля'
      })
      return
    }
    onOk?.(formData)
  }, [formData])

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e)
  }

  const onChange = useCallback(data => {
    setFormData(prevData => {
      return {
        ...prevData,
        ...data.formData,
      }
    })
  }, [setFormData])

  interface ISchemaActual {
    resultObject: {}
    resultSchema: {
      properties: {
        vehicle_id: any
      }
      required: string[]
    }
  }

  const actualSchema = useMemo(() => {
    const schemaActual = convertData2JsonForm(schema.fields) as never as ISchemaActual

    if (schemaActual.resultSchema && schemaActual.resultSchema.properties) {
      if (enums.logistics_vehicle) {
        schemaActual.resultSchema.properties.vehicle_id.oneOf = enums.logistics_vehicle
          .map((manager: any) => ({ const: manager.id, title: manager.value }))
        schemaActual.resultSchema.properties.vehicle_id.enum = schemaActual.resultSchema.properties.vehicle_id.oneOf
          .reduce((acc, curr) => {
            acc.push(curr.const)
            return acc
          },[])
      }
    }

    return schemaActual.resultSchema || {}
  }, [schema, formData, enums])

  if (!isModalOpen) {
    return null
  }

  return (
    <Modal
      title={schema.title}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={isModalOpen}
      destroyOnClose={true}
      okButtonProps={{
        disabled: loading
      }}
      okText="Отправить в WD и Шедекс"
    >
      {loading ? (
        <Spin />
      ) : (
        error
          ? (<Alert message={error} type='error' showIcon />)
          : (
            <DefaultJsonForm
              formData={formData}
              schema={actualSchema}
              onChange={onChange}
              orientation='horizontal'
            />
          )
      )}
    </Modal>
  )
})
